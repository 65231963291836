<template>
  <div class="album">
    <EditAlbum />
  </div>
</template>

<script>

import EditAlbum from './edit/Album'

import { mapActions } from 'vuex'

export default {

  name: 'Create',

  components: {
    EditAlbum
  },

  methods: mapActions(['clearAlbum']),

  mounted () {
    this.clearAlbum()
  }
}
</script>

<style lang="postcss" scoped>
  .album {
    @apply h-full overflow-hidden
  }
</style>
